import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutNosotros from '../nosotros/layoutNosotros';
import CardVideosComponent from './CardVideos';
import DetallesPropaganda from './DetallesPropaganda';

export const videosGob = [
  {
    img: '../img/propaganda/gob/video-gob-1.JPG',
    url: 'https://media.publit.io/file/Propaganda/GobiernoInterino/No-Me-Pidas-final.html?player=public01&at=eyJpdiI6IkVaY2JCeXNUdEc0Z2VjNndSRHk1VHc9PSIsInZhbHVlIjoiaGdiRUd4bUpNZ3d6UHB0U1Y5N0hQVE85WDI4NWR5WGxqVy9aWlZmMnJpND0iLCJtYWMiOiI4Mjk4YTAyNmViZThkNDgwOTI0MDVkNWJhOTMxMjI0OGY2MmZhOWJlNDAzYTlhYzk2MTJlZGE2NTRjNjFjZGEyIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/gob/video-gob-2.JPG',
    url: 'https://media.publit.io/file/Propaganda/GobiernoInterino/Esta-Vaina-Es-Pa-Locos-mercado-final.html?player=public01&at=eyJpdiI6Inp1ZDQzaGpmQy9HQ0xrSjB5N3ZMVkE9PSIsInZhbHVlIjoiMERXZGwvc0syekZva21md1lhWjdiT1RMd1Fud2x6ZTFISGQ1WEtxdE1Vaz0iLCJtYWMiOiI4N2QwNDM0Y2UxNzdjYTdmNTY0NTJmNDRlMTRlOTc5MWFmNzc3ZTgwNjkxNmYzNjRiZGQ1N2IzODI0NDZjOTdlIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/gob/video-gob-3.JPG',
    url: 'https://media.publit.io/file/Propaganda/GobiernoInterino/Esta-Vaina-Es-Pa-Locos-salud-final-baja.html?player=public01&at=eyJpdiI6Ilh1blJjOHNNUkJTcml2djRlWlA0b2c9PSIsInZhbHVlIjoiRTJ3bDhGNXRXeDY3QXozOWlscC9qamwwRUZXSWR5ZEVvYVYzYjNPL1hIZz0iLCJtYWMiOiI0OWY1MGNlMTVmOTVkNGQyOTk4YTY2YmYwMzZkOTM1OWIxOTdjMjFmNWEyOTNkYzUyZWVkYzhjMzVkNGQ4OGZkIiwidGFnIjoiIn0=',
  },
  {
    img: '../img/propaganda/gob/video-gob-4.JPG',
    url: 'https://media.publit.io/file/Propaganda/GobiernoInterino/Esta-vaina-es-pa-locos-versi-n-agua-mus.html?player=public01&at=eyJpdiI6IlNzWVJpdDl3b2RKQ1JHc05qSGozZmc9PSIsInZhbHVlIjoiZCtaTlg5UUJLZFk5d1RLL2hkV0QrbUgxUTVFTHlBaGlBT1U4N042bTkxVT0iLCJtYWMiOiI4N2YyOGE0ZDUwYThkNTEwNDExZGQ1ZjI3NmFiNWQwYmFlZWQxZDgyOTQ5MWI1YjI2YzlhNjljZDczZGE2MmRhIiwidGFnIjoiIn0=',
  },
];

const GobiernoPage = () => {
  const { t } = useTranslation();

  const graficas = [
    '../img/propaganda/gob/gob1.png',
    '../img/propaganda/gob/gob2.png',
    '../img/propaganda/gob/gob3.png',
  ];

  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState('');

  const toggle = () => setModal(!modal);

  return (
    <LayoutNosotros
      title={'GOBIERNO INTERINO'}
      subTitle={t('publicidad.propaganda')}
      isSmooth
      logo="../img/logo.png"
      srcImg="../img"
    >
      <DetallesPropaganda
        infoE="Realizar spots que reflejan la dura cotidianidad que viven los venezolanos."
        infoR="Despertar a la gente e incentivarlos a protestar frente al gobierno."
        graficas={graficas}
        modal={modal}
        toggle={toggle}
        videoLink={videoLink}
        categoria="gob"
      >
        {videosGob.map((el) => {
          return (
            <CardVideosComponent
              img={el.img}
              onClick={() => {
                setModal(true);
                setVideoLink(el.url);
              }}
            />
          );
        })}
      </DetallesPropaganda>
    </LayoutNosotros>
  );
};

export default GobiernoPage;
